import React from "react"
import { Link } from "gatsby"

import berlinjs from "../utils/reactday.jpeg"
import reactdaytalk from "../utils/reactdaytalk.jpeg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const imgStyles = {
  height: "400px",
  width: "300px",
  margin: "0 auto",
  display: "flex",
  marginBottom: "20px",
}

const AboutMe = props => {
  const title = "Meghna Srivastava"
  const page = {
    title: "About Me",
    content: `<h2>About me</h2>
  <p>I graduated as a Bachelor of Dental Surgery from India, worked for couple of years as a Dentist before switching to tech industry. I made a career transition in tech 2 years back, firstly by landing an internship at a startup and later getting hired as a full-time employee at a reputed company in Berlin, Germany.
  <br><br>
  I've learned web development through online resources on sites like Udemy, Traversy media courses on YouTube, coding problems on freeCodeCamp, hackerrank, codewars, codecademy, and articles on Medium, dev.to, or any pages that were on top in Google search result. 
  Programming was a great career choice for me since I was always fascinated by digital space and also the industry gives me flexibility to get a job in any city or country.
</p>
  <p>I&#8217;ve been working at <a href="https://www.auto1-group.com" target="_blank" rel="noopener noreferrer">AUTO1 Group </a> - Europe's leading digital automotive platform since January 2019 as a Frontend Developer.</p>
  <h2>Technical Skills</h2>
  <table>
  <tr>
    <td>Programming languages</td>
    <td>Typescript, Javascript, ES6+</td>
    </tr>
  <tr>
    <td>Frameworks/Libraries</td>
    <td>React, Redux, CSS-in-JS, Flow-type</td>
  </tr>
  <tr>
    <td>Testing</td>
    <td>Jest, Enzyme, React-testing-library</td>
  </tr>
  <tr>
    <td>Frontend-specific</td>
    <td>HTML5, CSS, SCSS, Stylus, Bootstrap, Ant-design, Material-UI</td>
  </tr>
  <tr>
    <td>Tools</td>
    <td>Git, Postman, VS code, Prettier, ESLint, TSLint</td>
  </tr>
</table>
  <h2>Speaking</h2>
  <p>I like to get involved in community-engaging programmes, hence took part in my company's internal hackthon last year and won a prize along with my team. I also have an interest to do public speaking, so in 2019 I did two public speaking talks.</p>
  
  <ul>
  <li><a href="https://slides.com/hellomeghna/git-commands-every-developer-should-know#" target="_blank" rel="noopener noreferrer">“Basic Git commands every developer should know”</a> at <a href="https://berlinjs.org" target="_blank" rel="noopener noreferrer">BerlinJS</a></li>
  <li><a href="https://www.youtube.com/watch?v=YzWC1CeqPo4" target="_blank" rel="noopener noreferrer">“Short Story: Dentist turned Software Developer”</a> - how I changed my careers into tech, what were the struggles, what are the suggestions I want to give to land your first job in tech at <a href="https://reactday.berlin" target="_blank" rel="noopener noreferrer">React Day Berlin</a></li></li>
  </ul>`,
  }

  return (
    <Layout location={"/about"} title={title}>
      <SEO title={page.title} />
      <div
        dangerouslySetInnerHTML={{ __html: page.content }}
        className="about"
      ></div>
      <div className="about_images_container">
        <img src={reactdaytalk} alt="Talk at React Day" style={imgStyles} />
        <img src={berlinjs} alt="Talk at React Day" style={imgStyles} />
      </div>
      <Link
        to={`/experience`}
        className={"button button__primary button__primary--about"}
      >
        View Experience
      </Link>
    </Layout>
  )
}

export default AboutMe
